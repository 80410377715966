import { motion } from "motion/react";
import { useEffect, useState } from "react";
import { ProgressButtons } from "./progressButtons";
import { PollQuestion } from "@/types/poll";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAxios } from "@/utils/axios";
interface ResultsInterface {
    question: PollQuestion;
    next: () => void;
    hideNext?: boolean;
    selected: string | string[];
    pollId: string;
    mock?: boolean;
    lookback?: boolean;
}

export default function Results({
    question,
    next,
    hideNext,
    selected,
    pollId,
    mock,
    lookback = false,
    // Lookback is for the polls overview page where a user can look back at their answered polls
}: ResultsInterface) {
    const axios = useAxios();
    const [result, setResult] = useState<any>(null);
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    useEffect(() => {
        if (mock) {
            setResult({
                data: {
                    question: {
                        options: question.options?.map((item) => ({
                            option: item,
                            count: 2,
                            percentage: 20,
                        })),
                    },
                },
            });
        } else {
            const fetchData = async () => {
                try {
                    const { data: response } = await axios.get(
                        route("polls.user.results", pollId),
                    );
                    setResult(response);
                } catch (e) {
                    console.error(e);
                }
            };
            fetchData();
        }
    }, []);
    if (!result) {
        return null;
    }
    const max = result.data.question.options.reduce(
        (p, c) => Math.max(p, c.count),
        0,
    );
    return (
        <div className="flex flex-col h-full grow">
            <span className="text-xl pb-1 min-h-14">{question.question}</span>
            {!lookback && (
                <span className="text-gray-600 pb-4">
                    <FormattedMessage
                        id={
                            question.option_type === "radio"
                                ? "Single selection"
                                : "Multiple selection"
                        }
                    />
                </span>
            )}
            <div className="flex flex-col gap-y-3 justify-between h-full grow">
                <div>
                    {result.data.question.options.map((item) => (
                        <motion.div
                            key={item.option}
                            initial={{ width: 0 }}
                            animate={{ width: "100%" }}
                            className="shrink"
                        >
                            <div
                                className={`flex justify-between font-medium pb-1 ${selected.includes(item.option) ? "text-voice" : ""}`}
                            >
                                {item.option}
                                {selected.includes(item.option) ? (
                                    <div className="flex">
                                        <CheckCircleIcon className="size-5" />
                                        <p className="text-sm">My answer</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="flex gap-x-4 items-center">
                                <div
                                    className={`w-4/5 rounded-full dark:bg-gray-700 h-4 ${selected.includes(item.option) ? "bg-voice-xlight" : "bg-gray-200"}`}
                                >
                                    <span className="sr-only">{`${
                                        item.percentage
                                    }%`}</span>
                                    <div
                                        className="bg-linear-to-r from-[#FF6097] to-[#FF0034] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full h-4"
                                        style={{
                                            width: `${(item.count / max) * 100}%`,
                                        }}
                                    />
                                </div>
                                <span
                                    className={` ${selected.includes(item.option) ? "text-voice" : ""}`}
                                >
                                    {item.percentage}%
                                </span>
                            </div>
                        </motion.div>
                    ))}
                </div>
                {lookback && (
                    <div className="text-gray-600">
                        <FormattedMessage
                            id="count.votes"
                            values={{ count: result.data.total_participants }}
                        />{" "}
                        {!!result.data.closing_at &&
                            (dayjs(result.data.closing_at).isAfter(dayjs()) ? (
                                <FormattedMessage
                                    id=" · :duration left"
                                    values={{
                                        duration: dayjs
                                            .duration(
                                                dayjs(
                                                    result.data.closing_at,
                                                ).diff(dayjs()),
                                            )
                                            .humanize(),
                                    }}
                                />
                            ) : (
                                <>
                                    {" · "}
                                    <FormattedMessage id="Poll closed" />
                                </>
                            ))}
                    </div>
                )}
            </div>
            {hideNext !== true && <ProgressButtons next={next} />}
        </div>
    );
}
